<template>
  <div class="login_box">
    <po-Three></po-Three>

    <div class="content_box">
      <div class="left_box">
        <img class="login_left_img" src="https://cargocnwebimage.oss-cn-beijing.aliyuncs.com/hhypc/logo-left-img.png" alt="">
      </div>
      <div class="right_box">
        <div class="sign_in">
          <div class="logo_box">
            <img class="logo_img" src="https://cargocnwebimage.oss-cn-beijing.aliyuncs.com/hhypc/logo-img.png" alt="">
            <div class="welcome">欢迎登录汉鸭子平台</div>
          </div>

          <div>
            <el-form :model="loginForm" :rules="rules" ref="loginForm" class="demo-ruleForm">
              <el-form-item prop="loginId" style="padding-bottom: 20px;">
                <el-input v-model="loginForm.loginId" placeholder="请输入用户名"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input v-model="loginForm.password" placeholder="请输入密码" type="password"></el-input>
              </el-form-item>

              <el-button :loading="loginLoading" type="primary" class="loginBtn" @click="signIn('loginForm')">登 录</el-button>
            </el-form>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import ajax from "axios";
import baseURL from "@/api/evn";
import poThree from "@/components/three";
import { mapMutations } from "vuex";
export default {
  components: {
    poThree,
  },
  data() {
    return {
      loginForm: {
        loginId: "",
        password: "",
        loginType: "12",
        isWebLogin: 1,
      },
      rules: {
        loginId: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loginLoading: false,
    };
  },
  mounted() {
    var loginUserName = localStorage.getItem("loginUserName");
    var loginPassward = localStorage.getItem("loginPassward");
    this.loginForm.loginId = loginUserName || "";
    this.loginForm.password = loginPassward || "";
  },
  methods: {
    ...mapMutations(["login"]),
    signIn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.login();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    login() {
      this.loginLoading = true;
      var url = baseURL.BASE_URL + "/au/login";
      let params = this.loginForm;
      console.log(params, "params");
      ajax
        .post(url, params)
        .then((res) => {
          console.log(res, "res");
          if (res.data.retCode == "0000000") {
            this.loginLoading = false;
            var data = res.data.rspBody;
            let user = {
              compId: data.compId,
              fsid: data.fsid,
              loginId: data.loginId,
              loginType: data.loginType,
              strProSignId: data.strProSignId,
              strUserReqChain: data.strUserReqChain,
              userId: data.userId,
              userName: data.userName,
              userType: data.userType,
            };
            localStorage.setItem("userInfo", JSON.stringify(user)); //用户信息
            localStorage.setItem(
              "menuList",
              JSON.stringify(res.data.rspBody.attrs.res)
            ); //左侧导航菜单
            localStorage.setItem("token", res.data.rspBody.attrs.token); //token
            this.$store.commit("login", user);
            this.$store.commit("menuEvent", res.data.rspBody.attrs.res);
            this.$message({
              type: "success",
              message: "登录成功",
            });
            localStorage.setItem("loginUserName", params.loginId);
            localStorage.setItem("loginPassward", params.password);
            this.$router.push("/home"); // 去主页
          } else {
            this.loginLoading = false;
            this.$message({
              type: "error",
              message: res.data.retDesc,
            });
          }
        })
        .catch((err) => {
          this.loginLoading = false;
          this.$message({
            type: "error",
            message: err.data.retDesc,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login_box {
  width: 100%;
  height: 100%;

  position: relative;

  .content_box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .left_box {
      flex: 1;
      .login_left_img {
        width: 70%;
      }
    }
    .right_box {
      flex: 1;
      display: flex;
      justify-content: center;
      .sign_in {
        width: 40%;
        .logo_box {
          text-align: left;
          padding-bottom: 50px;
          .logo_img {
            width: 200px;
            height: 40px;
          }
          .welcome {
            font-size: 14px;
            color: #939393;
          }
        }
        .loginBtn {
          width: 50%;
          height: 46px;
          font-size: 18px;
          margin-top: 50px;
        }
      }
    }
  }
}
</style>